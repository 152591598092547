import React from "react";
import { useState } from "react";
import { IoCloseSharp } from "react-icons/io5";
import popup from "../img/popup.png";

const Popup = () => {
  const [open, setOpen] = useState(false);

  return (
    // <div className={`${open ? "fixed" : "hidden" } w-screen h-screen overflow-x-hidden bg-black/70 z-[1000]`}>
    // <div className='absolute w-5/6 max-w-[480px] h-auto md:w-1/2 lg:w-[480px] bg-transparent top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 '>
    //    <IoCloseSharp size={40} className="absolute -right-5 lg:-right-14 -top-12 text-white cursor-pointer" onClick={() => setOpen(false)} />
    //    <img src="assets/images/popup.png" alt="popup_image" className='w-full h-full object-contain' />
    //    <a href='https://www.tbdine.com/book/restaurant/the-farmhouse-restaurant?idApp=70973&language=en-us' rel="noreferrer" target="_blank" className='absolute left-5 sm:left-8 lg:left-10 bottom-6 lg:bottom-10 tracking-widest text-sm lg:text-lg cursor-pointer font-primary lowercase bg-black text-white py-1 md:py-1.5 px-1.5 md:px-3 whitespace-nowrap transition-all hover:bg-th-red hover:text-white'>RESERVE NOW</a>
    // </div>
    // </div>
    <div
      className={`${
        open ? "fixed" : "hidden"
      } w-screen h-screen overflow-x-hidden bg-black/70 z-[1000]`}
    >
      <div className="absolute w-5/6 max-w-[480px] h-auto md:w-1/2 lg:w-[480px] bg-transparent top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 bg-[#EAE4DA]">
        <IoCloseSharp
          size={40}
          className="absolute -right-5 lg:-right-14 -top-12 text-white cursor-pointer"
          onClick={() => setOpen(false)}
        />
        <div className="relative">
          <img
            src={popup}
            alt="popup_image"
            className="w-full h-full object-contain"
          />
          {/* <a
            href="https://www.tbdine.com/book/restaurant/the-farmhouse-restaurant?idApp=70973&language=en-us"
            rel="noreferrer"
            target="_blank"
            className="absolute left-[45%] bottom-1 tracking-widest text-sm cursor-pointer font-primary lowercase bg-black text-white py-1 px-1.5 whitespace-nowrap transition-all hover:bg-th-red hover:text-white
            md:py-1.5 md:px-3 md:left-[48%] md:bottom-2
            lg:left-[47%] lg:bottom-2 lg:text-lg"
          >
            RESERVE NOW
          </a> */}
        </div>
      </div>
    </div>
  );
};

export default Popup;
